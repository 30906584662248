<template>
  <div id="container" v-if="post">
    <vue-editor v-show="false"/> <!-- dummy -->
    <section v-bind:class="{signed_out: !isUserSignedIn()}">
      <div class="wrap">
        <div class="cts_box">
          <h2 class="main_ttl"><span>
            <router-link :to=spaceUrl()>{{ space_title() }}</router-link></span>
          </h2>
          <p class="mb20" v-html="space_description()"></p>
        </div>

        <div class="cts_box">
          <!--フィード1セット-->
          <div class="feed_box" v-bind:id="'post-' + post.id" v-bind:class="post.status">
            <!-- post menu -->
            <SnsPostMenu :post="post" :show_permalink_menu="false" @editPost="editPost" @reportPost="reportPost"
                         @deletePost="deletePost" @hidePost="hidePost"
                         @playedTest="playedTest" @audioPlayedTest="audioPlayedTest"
                         v-if="isUserSignedIn()"/>

            <div class="user_info flex">
              <div class="icon achievement" v-bind:class="post.user.dan">
                <router-link
                    :to="{ name: 'SnsTimeline', params: {user_id: post.user.id}}">
                  <img v-bind:src="post.user.profile_image_url" alt="">
                </router-link>
              </div>
              <div class="detail">
                <div class="user_name">
                  <router-link :to="{ name: 'SnsTimeline', params: {user_id: post.user.id}}">
                    {{ post.user.sns_user_name }}
                  </router-link>
                </div>
                <div class="post_day">{{ moment(post.published_at, 'YYYY/MM/DD HH:mm') }}</div>
                <div class="favorite" v-bind:class="{active: post.favorite}" v-if="isUserSignedIn()">
                  <a href="javascript:void(0)" class="flex" @click="favoritePost(post)">
                    <img v-bind:src="`/common/images/icon/star_favorite_${post.favorite}.svg`" alt="お気に入り">
                    <p>お気に入り</p>
                  </a>
                </div>
              </div>
              <div class="hashtag" v-if="post.sns_tag">
                <router-link :to="{name: 'SnsSearch', query: {query: tag.substring(1)}}" v-for="tag in post.sns_tag.split(' ')" :key=tag>
                  {{ tag }}
                </router-link>
              </div>
            </div>
            <div class="tag">
              <router-link :to="space_url(post)">{{ space_title() }}</router-link>
            </div>
            <div class="post_title" v-if="post.title && post.title.length > 0" v-html="queryMarker(htmlEscape(post.title), query)"></div>
            <div class="post_body ql-container ql-snow" v-if="!post.edit_mode">
              <div class="post_body ql-editor" v-html="bodyEssence(post.body, query)"></div>
            </div>

            <transition>
              <div class="cts_box" v-if="post.edit_mode">
                <div class="acc_menu post_form post_form_edit">
                  <h3 class="min_ttl acc_tab" v-bind:class="{'open': post.edit_mode}" v-on:click="editPost(post)"><span>投稿内容の編集</span>
                  </h3>
                  <SnsPostForm :post="post" :tags="fetch_data.tags" v-on:saved="savePost"/>
                </div>
              </div>
            </transition>

            <div v-if="!post.edit_mode">
              <!-- ogp tag -->
              <SnsOgpTags :ogp_tags="post.ogp_tags"/>
              <!-- hedge txt -->
              <SnsPostHedgeText v-if="post.user.dan === 'rank_staff'" />
              <!-- post files -->
              <SnsFiles :files="post.files"/>

              <div class="post_info flex" v-if="isUserSignedIn()">
                <div class="nice_box flex">
                  <div class="nice" v-on:mouseover="likeOver(post)" v-on:mouseleave="likeLeave(post)"
                       @click="likeClick(post)"><span>{{ delimited(post.like_user_count) }}</span></div>
                  <div class="btn_nice" v-bind:class="{active: post.like_user}" v-on:click="likeUserPost(post)">
                    <span>いいね！</span>
                  </div>
                  <SnsLikeUserList :post="post" v-if="post.show_like_list"/>
                  <SnsLikeUserModal :post="post" v-if="post.show_like_modal" @close="likeModalClose(post)"
                                    @follow="follow" @un_follow="unFollow"/>
                </div>

                <div class="comment">コメント<span>{{ delimited(post.comment_count) }}</span>件</div>
              </div>
              <div class="comment_more" v-if="isUserSignedIn() && post.comments.length < post.comment_count"
                   v-on:click="fetchComments(post)">
                以前のコメントを全て見る（<span>{{ delimited(post.comment_count) }}</span>件）
              </div>

              <!--コメント1セット-->
              <div v-if="comment_addable()">
                <div class="comment_body flex" v-for="(comment, key) in post.comments" :key="key"
                     v-bind:id="'comment-' + comment.id">
                  <div class="icon achievement" v-bind:class="comment.user.dan">
                    <router-link
                        :to="{ name: 'SnsTimeline', params: {user_id: comment.user.id}}">
                      <img v-bind:src="comment.user.profile_image_url" alt="">
                    </router-link>
                  </div>
                  <div class="comment_post_box">
                    <div class="inner">
                      <!-- comment menu -->
                      <SnsCommentMenu :comment="comment" @editComment="editComment(comment)"
                                      @reportComment="reportComment(post, comment)"
                                      @deleteComment="deleteComment(post, comment)"/>

                      <div class="head flex">
                        <div class="user_name">
                          <router-link :to="{ name: 'SnsTimeline', params: {user_id: comment.user.id}}">
                            {{ comment.user.sns_user_name }}
                          </router-link>
                        </div>
                        <div class="post_day">{{ moment(comment.updated_at, 'YYYY/MM/DD HH:mm') }}</div>
                      </div>
                      <div class="comment_post_body ql-container ql-snow" v-if="!comment.edit_mode">
                        <div class="post_body ql-editor" v-html="bodyEssence(comment.body, query)"></div>
                      </div>

                      <transition>
                        <SnsPostCommentForm :post="post" :comment="comment" v-if="comment.edit_mode"
                                            v-on:saved="saveComment"/>
                      </transition>
                    </div>
                    <div v-if="!comment.edit_mode">
                      <SnsFiles :files="comment.files"/>
                      <div class="comment_info flex">
                        <div class="comment_nice" v-bind:class="{active: comment.like_user}"
                             v-on:click="likeUserComment(comment)">いいね！
                        </div>
                        <div class="count" v-on:mouseover="commentLikeOver(comment)"
                             v-on:mouseleave="commentLikeLeave(comment)" @click="commentLikeClick(comment)">
                          （<span>{{ delimited(comment.like_user_count) }}</span>）
                        </div>
                        <SnsLikeUserList :comment="comment" v-if="comment.show_like_list"/>
                        <SnsLikeUserModal :comment="comment" v-if="comment.show_like_modal"
                                          @close="commentLikeModalClose(comment)" @follow="follow"
                                          @un_follow="unFollow"/>
                      </div>
                    </div>
                  </div>
                </div>

                <!--コメント1セットここまで-->
                <div class="post_btn flex">
                  <div class="btn_comment" v-if="comment_addable()" v-bind:class="{disabled: !isUserJoin()}"
                       v-on:click="togglePostCommentForm(post)">
                    <span>コメントする</span></div>
                </div>
              </div>

              <transition>
                <SnsPostCommentForm :post="post" :comment="new_comment"
                                    v-if="new_comment.edit_mode && new_comment.sns_post_id === post.id"
                                    v-on:saved="newComment"/>
              </transition>
            </div>
          </div>

        </div>
      </div>
    </section>
  </div>
</template>

<script>
import Libraries from '@/components/Libraries.vue'
import SnsCommons from '@/components/sns/SnsCommons.vue'
import SnsOgpTags from '@/components/sns/SnsOgpTags.vue'
import SnsFiles from '@/components/sns/SnsFiles.vue'
import SnsPostForm from '@/components/sns/SnsPostForm.vue'
import SnsPostMenu from '@/components/sns/SnsPostMenu.vue'
import SnsPostCommentForm from '@/components/sns/SnsPostCommentForm.vue'
import SnsCommentMenu from '@/components/sns/SnsCommentMenu.vue'
import {VueEditor} from 'vue2-editor';
import SnsLikeUserList from "@/components/sns/SnsLikeUserList";
import SnsLikeUserModal from '@/components/sns/SnsLikeUserModal'
import SnsPostHedgeText from "@/components/sns/SnsPostHedgeText.vue";

export default {
  mixins: [Libraries, SnsCommons],
  components: {
    SnsOgpTags,
    SnsFiles,
    SnsPostForm,
    SnsPostMenu,
    SnsPostCommentForm,
    SnsCommentMenu,
    SnsLikeUserList,
    SnsLikeUserModal,
    VueEditor,
    SnsPostHedgeText
  },

  data() {
    return {
      query: this.$route.query.query
    }
  },
  methods: {
    fetchData() {
      this.scrollTop()
      this.startAjax()
      this.axios
          .get(`${this.env.api_base_url}sns/post.json`, {
            params: {
              id: this.post_id
            }
          })
          .then(response => {
            // console.log(response.data)
            this.fetch_data = response.data
            this.post = this.fetch_data.post
            this.post.show_like_list = false
          })
          .catch((response) => {
            this.errorAjax(response)
          })
          .finally(() => {
            this.finishAjax()

            this.$nextTick(() => {
              this.vimeoPlayTrigger(this.post)
            });
          })
    }
  }
}
</script>
